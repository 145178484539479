import { useQueries } from '@tanstack/react-query';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { QueryKeys } from '@openx/utils/api';

import { useAuth } from '../components/AuthContext';
import type { InstanceListOption, Session } from '../types';

import {
  type FetchInstancesListResponse,
  fetchInstancesListResponse,
} from './fetchInstancesListResponse';
import {
  type FetchSessionInfoResponse,
  fetchSessionInfoResponse,
} from './fetchSessionInfoResponse';
import type { FetchSessionInfoError } from './types';
import { getOAuthParams } from './verifyOAuthParams';

type UseFetchSessionInfoReturnType = FetchSessionInfoResponse &
  FetchInstancesListResponse & {
    loading: boolean;
  };

export const useFetchSessionInfo = (): UseFetchSessionInfoReturnType => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const [instancesList, setInstancesList] = useState<InstanceListOption[]>([]);
  const [registered, setRegistered] = useState<boolean>(false);
  const [session, setSession] = useState<Session | null>(null);
  const [error, setError] = useState<FetchSessionInfoError | null>(null);
  const { sessionId } = getOAuthParams(location);

  useQueries({
    queries: [
      {
        meta: {
          errorMessage: 'Failed to fetch instance list',
        },
        queryFn: async () => {
          const response = await fetchInstancesListResponse({
            currentUser,
          });

          if (response.error) {
            setError(response.error);
          }

          if (response.registered) {
            setRegistered(response.registered);
          }

          if (response.instancesList) {
            setInstancesList(response.instancesList);
          }

          return response;
        },
        queryKey: [QueryKeys.LOGIN_APP_INSTANCE_LIST, currentUser?.uid],
        retry: false,
      },
      {
        meta: {
          errorMessage: 'Failed to fetch session info',
        },
        queryFn: async () => {
          const response = await fetchSessionInfoResponse({
            currentUser,
            sessionId,
          });

          if (response.error) {
            setError(response.error);
          }

          if (response.session !== null) {
            setSession(response.session);
          }

          return response;
        },
        queryKey: [QueryKeys.LOGIN_APP_SESSION_INFO, currentUser?.uid],
        retry: false,
      },
    ],
  });

  const isReady = !!instancesList.length && !!session;
  const loading = !error && !isReady;

  return {
    error,
    instancesList,
    loading,
    registered,
    session,
  };
};
